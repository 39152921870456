import './App.css';
import { Slide } from 'react-slideshow-image';
import Form from './Form'
import 'react-slideshow-image/dist/styles.css';

import slide1 from './images/slide1.png';
import slide2 from './images/slide2.png';
import slide3 from './images/slide3.png';
import logo from './images/logo.png';
import logowhite from './images/logo-white.png';
import insta from './images/instagram_icon.png';
import face from './images/facebook_icon.png';

const properties = {
  duration: 2000,
  transitionDuration: 1000,
  indicators: true,
  arrows: false,
  pauseOnHover: false
}

function App() {

  return (
    <div className="App">

      <div className="slide-container">
        <Slide {...properties}>
          <div className="each-slide">
            <div className="slide1" style={{'backgroundImage': `url(${slide1})`}}>
              <div className="logo_container">
                <img className="logo" src={logo}></img>
              </div>
              <div className="subtitle_container">
                <h1 className="subtitle hidden-phone">A new horizon is emerging in hair cosmetics</h1>
                <h1 className="subtitle hidden-desktop">A new horizon<br></br> is emerging<br></br> in hair cosmetics</h1>
              </div>
              <div className="coming_container">
                <h1 className="coming">coming soon</h1>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div className="slide2" style={{'backgroundImage': `url(${slide2})`}}>
              <div className="logo_container">
                <img className="logo" src={logowhite}></img>
              </div>
              <div className="subtitle_container">
                <h1 className="subtitle hidden-phone">A long future starts in a solid base</h1>
                <h1 className="subtitle hidden-desktop">A long future<br></br> starts in a solid base</h1>
              </div>
              <div className="coming_container">
                <h1 className="coming">coming soon</h1>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div className="slide3" style={{'backgroundImage': `url(${slide3})`}}>
              <div className="logo_container">
                <img className="logo" src={logo}></img>
              </div>
              <div className="subtitle_container">
                <h1 className="subtitle hidden-phone">Work in progress for a new beauty</h1>
                <h1 className="subtitle hidden-desktop">Work in progress<br></br> for a new beauty</h1>
              </div>
              <div className="coming_container">
                <h1 className="coming">coming soon</h1>
              </div>              
            </div>
          </div>
        </Slide>
      </div>

      <div className="bottom_bar">
        <div className="notify_container">
          <Form/>
        </div>

        <div className="link_container" >
          <a className="link_contact" href="mailto:hello@dharie.com"><h1 className="contact">hello@dhaire.com</h1></a>
        </div>

        <div className="follow_container">
          <h1>Follow us</h1>
          <div className="sociais">
            <a href="https://www.instagram.com/dhaire_beauty/" target="_blank" rel="noreferrer" ><img src={insta}></img></a>
            <a href="https://www.facebook.com/Dhaire-beauty-100693572120182" target="_blank" rel="noreferrer" ><img src={face}></img></a>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
