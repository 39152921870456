import React from 'react';
import './App.css';
import send from './images/Iconssend.svg';
import { db } from './firebase';


function isValidEmailAddress(address) {
    return !! address.match(/\S+@\S+\.\S+/);
}

export default class Form extends React.Component {

    state = { email: "" };

    change = e => {
      this.setState({
        [e.target.name] : e.target.value 
      });
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (isValidEmailAddress(this.state.email)) {

            var email_save = this.state.email;
            var emailexists = false;

            db.collection('emails_notify')
            .get()
            .then( (snapshot) => {

                if(!snapshot.empty) {
                    snapshot.forEach( (doc) => {
                        if(doc.data().email === email_save) {
                            emailexists = true;
                        }
                    })

                    if(!emailexists) {
                        db.collection('emails_notify').add({
                            email: email_save    
                        })
                        .then( () => {
                            alert('Email has been submitted! 👍');
                        })
                        .catch ( (error) => {
                            console.log(error);
                        })
                    } else {
                        alert("That Email already exists! 👍");
                    }
                }
            })
            
        } else {
            alert("Please insert a valid Email!");
        }

        this.setState({
            email: ""
        });
    }

    render() {
        return  (
            <form className="notify_form">
                <input 
                    type="email" 
                    className="notify_input" 
                    placeholder="Notify me" 
                    name="email" 
                    value={this.state.email} 
                    onChange={e => this.change(e)} 
                ></input>
                
                <button className="button_notify" onClick={e => this.onSubmit(e)}>
                    <img src={send}></img>
                </button>
            </form>
        );
    }
}