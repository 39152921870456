import firebase from 'firebase';

var firebaseApp = firebase.initializeApp({

    apiKey: "AIzaSyC2DwwQt2D_TS81_8O8C8ALiyi8bMUkS8M",
    authDomain: "dhaire-7958d.firebaseapp.com",
    projectId: "dhaire-7958d",
    storageBucket: "dhaire-7958d.appspot.com",
    messagingSenderId: "867339149535",
    appId: "1:867339149535:web:59436d875a2a7e1127496a",
    measurementId: "G-1HKMK5SHJ2"

});

var db = firebaseApp.firestore();

export { db };